<template>
  <div class="date-picker" :style="{ paddingTop: paddingTop, paddingBottom: paddingBottom }">
    <div class="head" ref="head">
      <div class="title-wrapper">
        <div class="text">请您选择可用于面试的时间</div>
        <!-- <div class="desc">可选择5天中的6个时段</div> -->
      </div>
      <div class="tab-wrapper">
        <div class="tab" :class="{ active: tabActiveIndex === 1 }" @click="tabActiveIndex = 1">选择可面试时间</div>
        <div class="tab" :class="{ active: tabActiveIndex === 2 }" @click="tabActiveIndex = 2">选择不可面试时间</div>
      </div>
      <div class="tips">如果未选择任何时段，系统会默认您最近两周{{ tabActiveIndex === 1 ? '全都没空' : '全都有空' }}</div>
      <!-- <transition :name="isSlideLeft ? 'slide-left' : 'slide-right'"> -->
      <div class="weekDay-wrapper" ref="weekDayWrapper">
        <!-- <transition-group name="list-complete" tag="p"> -->
        <div
          class="weekDay-item-wrapper"
          v-for="(weekItem, index) in weekdayList"
          :key="index"
          @click="pickItem(weekItem, 'timeArr')"
          :class="{
            active: isAllIn(weekItem['timeArr'], 1) && tabActiveIndex === 1,
            forbiddenActive: isAllIn(weekItem['timeArr'], 2) && tabActiveIndex === 2,
          }"
        >
          <!-- {{ isAllIn(weekItem['timeArr'], 2) }} -->
          <div class="date-text">{{ weekItem.weekText }}</div>
          <div class="weekday-text">{{ weekItem.dateText }}</div>
        </div>
        <!-- </transition-group> -->
      </div>
      <!-- </transition> -->
    </div>
    <!-- <transition :name="isSlideLeft ? 'slide-left' : 'slide-right'"> -->
    <div class="container" v-show="!isSlideLeft && !isSlideRight" ref="touchPickItem">
      <div class="col" v-for="weekItem in weekdayList" :key="weekItem.key">
        <div
          class="pickItem "
          :class="{
            active: isAllIn(weekItem['morningArr'], 1) && tabActiveIndex === 1,
            forbiddenActive: isAllIn(weekItem['morningArr'], 2) && tabActiveIndex === 2,
          }"
          @click="pickItem(weekItem, 'morningArr')"
        >
          上午
        </div>
        <div
          v-for="(morningItem, index) in weekItem.morningArr"
          :key="'am' + weekItem.key + '-' + index"
          class="pickItem"
          :class="{
            active: isInArr(morningItem, activeList) > -1 && tabActiveIndex === 1,
            forbiddenActive: isInArr(morningItem, forbiddenActiveList) > -1 && tabActiveIndex === 2,
          }"
          @click="choseDateItem(morningItem, weekItem, 'chose')"
        >
          {{ morningItem.time }}
        </div>
        <div
          class="pickItem"
          @click="pickItem(weekItem, 'afternoonArr')"
          :class="{
            active: isAllIn(weekItem['afternoonArr'], 1) && tabActiveIndex === 1,
            forbiddenActive: isAllIn(weekItem['afternoonArr'], 2) && tabActiveIndex === 2,
          }"
        >
          下午
        </div>
        <div
          v-for="(afternoonItem, index) in weekItem.afternoonArr"
          :key="'pm' + weekItem.key + '-' + index"
          class="pickItem"
          :class="{
            active: isInArr(afternoonItem, activeList) > -1 && tabActiveIndex === 1,
            forbiddenActive: isInArr(afternoonItem, forbiddenActiveList) > -1 && tabActiveIndex === 2,
          }"
          @click="choseDateItem(afternoonItem, weekItem, 'chose')"
        >
          {{ afternoonItem.time }}
        </div>
      </div>
    </div>
    <!-- </transition> -->

    <div class="foot" ref="foot">
      <div class="submit-btn cancle" @click="cancle">取消</div>
      <div class="submit-btn" @click="submit">确认</div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
import { interviewerPlan, getInterviewerTime } from '@/api/candidate'
import { timeSlot } from '@/utils'

export default {
  data() {
    return {
      moment,
      message: '',
      tabActiveIndex: 1,
      today: new Date(),
      targetDate: null,
      weekdayList: [],
      morning: [],
      afternoon: [],
      dateArray: [],
      choseDateArray: [],
      choseForbiddenDateArray: [],
      paddingTop: '5.3rem',
      paddingBottom: '2rem',
      tempArray: [],
      forbiddenTimes: [],
      removeEventListener: '',
      toucheStore: {},
      slideStep: 0, //左滑为正
      currentDateArray: [], //当前targetDate的时间数组
      currentDateIndex: '', //当前targetDate的时间数组的索引
      activeList: [],
      forbiddenActiveList: [],
      morningArr: [],
      afternoonArr: [],
      isSlideLeft: false,
      isSlideRight: false,
      lastTwoWeeks: [],
    }
  },
  computed: {
    targetArray() {
      if (this.tabActiveIndex === 1) {
        return this.choseDateArray
      } else {
        return this.choseForbiddenDateArray
      }
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    positionId() {
      return this.$store.state.positionId
    },
  },

  mounted() {
    this.getTimeArr()
    this.targetDate = this.today
    this.getLastTwoWeeks()
    // const zeroPoint = this.today
    // zeroPoint.setHours(0) // 时分秒设置从零点开始
    // zeroPoint.setSeconds(0)
    // zeroPoint.setMinutes(0)
    // zeroPoint.setMilliseconds(0)
    // let date = zeroPoint.getTime()
    // this.lastTwoWeeks = this.getWeekdayList(date, 2)

    this.$nextTick(() => {
      this.paddingTop = this.$refs.head.clientHeight + 10 + 'px'
      this.paddingBottom = this.$refs.foot.clientHeight + 'px'
      console.log('this.$refs.weekDayWrapper', this.$refs.weekDayWrapper)
      this.touchPickItem = this.$refs.touchPickItem || ''
      if (this.touchPickItem) {
        this.touchPickItem.addEventListener('touchstart', this.touchstart)
        this.touchPickItem.addEventListener('touchend', this.touchend)
      }
    })

    // this.listInterviewerBusyTime()
  },
  beforeDestroy() {
    if (this.touchPickItem) {
      this.touchPickItem.removeEventListener('touchstart', this.touchstart)
      this.touchPickItem.removeEventListener('touchend', this.touchend)
    }
  },
  methods: {
    getLastTwoWeeks() {
      const zeroPoint = this.today
      zeroPoint.setHours(0) // 时分秒设置从零点开始
      zeroPoint.setSeconds(0)
      zeroPoint.setMinutes(0)
      zeroPoint.setMilliseconds(0)
      let date = zeroPoint.getTime()
      const twoWeeks = this.getWeekdayList(date, 2)
      const lastTwoWeeks = []
      twoWeeks.forEach(i => {
        i.timeArr.forEach(k => {
          lastTwoWeeks.push(k)
        })
      })
      this.lastTwoWeeks = lastTwoWeeks.map(i => {
        return { interviewerEndDate: i.interviewerEndDate, interviewerStartDate: i.interviewerEndDate }
      })
      console.log(twoWeeks)
    },
    getTimeArr() {
      const timeArr = timeSlot(30, 24)
      this.timeArr = timeArr.filter(i => {
        return i.value >= 28800000
      })
      this.morningArr = timeArr.filter(i => {
        return i.value >= 28800000 && i.value < 43200000 //8点到11点30
      })
      this.afternoonArr = timeArr.filter(i => {
        return i.value >= 43200000 && i.value <= 81000000 //12点到22点30
      })
      // console.log('====>', timeArr, this.timeArr)
    },

    touchstart(event) {
      console.log('start', event)
      let store = this.toucheStore
      let touches = []
      let events = ''
      // let events2 = ''
      touches = event.touches
      events = touches[0] || ''
      console.log('startevents', events)

      // 第一个触摸点的坐标
      if (events) {
        store.startX = (events && events.screenX) || ''
        store.startY = (events && events.screenY) || ''
      }

      this.toucheStore = { ...store }
    },

    touchend(event) {
      let touches = []
      let events = ''
      let store = {}
      console.log('end', event)
      touches = event.changedTouches
      events = touches[0] || ''
      console.log('endtouches', events)
      if (events) {
        store.endX = (events && events.screenX) || ''
        store.endY = (events && events.screenY) || ''
      }
      this.toucheStore = { ...this.toucheStore, ...store }
      const { startX, startY, endX, endY } = this.toucheStore
      const r = this.getDirection(startX, startY, endX, endY)
      console.log('r =>', r)
      if (r === 3) {
        console.log('左')
        this.isSlideLeft = true
        this.targetDateChangeWeek('+')
      }
      if (r === 4) {
        console.log('右')
        this.isSlideRight = true
        this.targetDateChangeWeek('-')
      }
      // const angle = Math.atan2(this.toucheStore.endX - this.toucheStore.startX, this.toucheStore.endY - this.toucheStore.endX)
      // console.log('angleangle', angle)
    },

    targetDateChangeWeek(type) {
      const date = this.targetDate.getTime()
      const aweek = 7 * 24 * 60 * 60 * 1000
      let targetDate = ''
      if (date) {
        targetDate = type === '+' ? date + aweek : date - aweek
        this.targetDate = new Date(targetDate)
      }
    },

    getDirection(startx, starty, endx, endy) {
      var angx = endx - startx
      var angy = endy - starty
      var result = 0
      console.log(endx, startx)
      console.log(endy, starty)

      //如果滑动距离太短 或者上下滑动大于左右滑动
      if ((Math.abs(angx) < 5 && Math.abs(angy) < 2) || Math.abs(angy) > Math.abs(angx)) {
        return result //未滑动
      }

      var angle = this.getAngle(angx, angy)
      console.log('angle', angle)
      console.log(angx, angy)
      if (angle >= -145 && angle <= -45) {
        console.log('上滑')
        result = 1 //上滑
      } else if (angle > 45 && angle < 145) {
        console.log('下滑')
        result = 2 //下滑
        //&& Math.abs(angy) < Math.abs(angx)
      } else if ((angle >= 145 && angle <= 180) || (angle >= -180 && angle < -145)) {
        console.log('左滑')
        result = 3 //左滑
        //&& Math.abs(angy) < Math.abs(angx)
      } else if (angle >= -45 && angle <= 45) {
        console.log('右滑')
        result = 4 //右滑
      }
      return result
    },

    getAngle(angx, angy) {
      return (Math.atan2(angy, angx) * 180) / Math.PI
    },

    changeDateArray() {
      console.log('changeDateArray')
      this.currentDateArray = []
      for (let i = 1; i <= 7; i++) {
        let tempTimeArray = []
        const targetDate = moment(this.targetDate).add(i - 1, 'days')
        for (let j = 0; j < 30; j++) {
          tempTimeArray.push({
            date:
              moment(targetDate)
                .set({ hours: 8, minute: 0, second: 0, millisecond: 0 })
                .add(30 * j, 'minutes')
                .unix() * 1000,
            freeFlag: false,
            forbidFlag: false,
          })
        }

        this.$set(this.currentDateArray, i, tempTimeArray)
      }
      const checkArr = this.currentDateArray[1]
      // console.log('isExist', this.isExist(checkArr))
      if (this.isExist(checkArr) && this.currentDateIndex) {
        this.currentDateArray = this.dateArray.slice(this.currentDateIndex - 1, this.currentDateIndex + 8)
        // console.log('1')
      } else {
        this.dateArray = this.dateArray.concat(this.currentDateArray)
      }
    },

    isExist(arr) {
      if (arr && arr.length) {
        const date = arr[0].date
        return (
          this.dateArray &&
          this.dateArray.length &&
          this.dateArray.some((item, index) => {
            // console.log('item', item, moment(date).format('YYYY-MM-DD HH:mm'))

            const isTrue =
              item &&
              item.length &&
              item.some(k => {
                // console.log('k===', moment(k.date).format('YYYY-MM-DD HH:mm'))
                return k.date === date
              })
            if (isTrue) {
              this.currentDateIndex = index
              return true
            } else {
              this.currentDateIndex = ''
              return false
            }
          })
        )
      } else {
        return ''
      }
    },

    listInterviewerBusyTime() {
      const endTime = this.weekdayList[6]?.date ?? 0
      const datTime = 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999
      getInterviewerTime({
        interviewerIdAry: [this.userInfo.userId],
        positionId: this.positionId,
        startTime: this.weekdayList[0]?.date ?? 0,
        endTime: endTime ? endTime + datTime : 0,
      }).then(res => {
        if (res && res.code === 0) {
          if (res.data && res.data.interviewerBusyTimeList && res.data.interviewerBusyTimeList.length) {
            this.tabActiveIndex = res.data.interviewerBusyTimeList[0].interviewerTimeType
            this.emptyFlag = true
          }
          const activeList = (res.data.interviewerBusyTimeList || [])
            .filter(i => {
              return i.interviewerTimeType === 1
            })
            .map(i => {
              return { interviewerId: i.interviewerId, interviewerStartDate: +i.start, interviewerEndDate: +i.end }
            })
          const forbiddenActiveList = (res.data.interviewerBusyTimeList || [])
            .filter(i => {
              return i.interviewerTimeType === 2
            })
            .map(i => {
              return { interviewerId: i.interviewerId, interviewerStartDate: +i.start, interviewerEndDate: +i.end }
            })

          /**
           *  date:1630857600355
           *  interviewerEndDate:1630888200355
           *  interviewerStartDate:1630886400355
           *  time:"08:00"
           *  value:28800000
           */
          activeList &&
            activeList.length &&
            activeList.forEach(i => {
              const index = this.isInArr(i, this.activeList)
              index === -1 ? this.activeList.push(i) : ''
            })
          forbiddenActiveList &&
            forbiddenActiveList.length &&
            forbiddenActiveList.forEach(i => {
              const index = this.isInArr(i, this.forbiddenActiveList)
              index === -1 ? this.forbiddenActiveList.push(i) : ''
            })

          // this.activeList = activeList
          // this.forbiddenActiveList = forbiddenActiveList
          // this.forbiddenTimes = res.data.interviewerBusyTimeList.filter(item => {
          //   return moment(+item.start).isSame(this.today, 'week')
          // })
          // this.computedForbiddenTimes()
        }
      })
    },

    submit() {
      const startAndEndTimeList = this.tabActiveIndex === 1 ? this.getStartAndEndTimeList(this.activeList) : this.getStartAndEndTimeList(this.forbiddenActiveList)
      console.log(this.tabActiveIndex, startAndEndTimeList)
      if (!startAndEndTimeList.length) {
        startAndEndTimeList.push({
          interviewerStartDate: 0,
          interviewerEndDate: 0,
        })
      }

      interviewerPlan({
        interviewerId: this.userInfo.userId,
        interviewerTimeType: this.tabActiveIndex,
        startAndEndTimeList: startAndEndTimeList,
      }).then(res => {
        if (res && res.code === 0) {
          this.$notify({
            type: 'success',
            message: '保存成功',
          })
          // setTimeout(() => this.$router.go(-1), 800)
        }
      })
    },

    getStartAndEndTimeList(arr) {
      const tmpArr = (arr || []).map(i => {
        return { interviewerStartDate: i.interviewerStartDate, interviewerEndDate: i.interviewerEndDate }
      })
      // if (this.tabActiveIndex === 2) {
      return tmpArr
      // } else {
      //   const forBidenArr = this.lastTwoWeeks.filter(i => {
      //     const index = tmpArr.findIndex(k => {
      //       return k.interviewerStartDate === i.interviewerStartDate
      //     })
      //     return index > -1 ? false : true
      //   })
      //   return forBidenArr || []
      // }
    },

    pickItem(weekItem, type) {
      const arr = weekItem[type] || []
      const isAllIn = this.isAllIn(arr, this.tabActiveIndex)

      //先判断全部选中
      arr.forEach(i => {
        if (isAllIn) {
          this.choseDateItem(i, weekItem, 'chose')
        } else {
          this.choseDateItem(i, weekItem, 'push')
        }
      })

      // console.log('isAllIn', isAllIn)
    },
    /**
     * 是否全部在数组里
     */
    isAllIn(arr, tabActiveIndex) {
      const isExist = arr.some(i => {
        if (tabActiveIndex === 1) {
          return this.isInArr(i, this.activeList) === -1
          // if (this.isInArr(i, this.activeList) === -1) {
          //   isAllIn = false
          // }
        } else {
          return this.isInArr(i, this.forbiddenActiveList) === -1
          // if (this.isInArr(i, this.forbiddenActiveList) === -1) {
          //   isAllIn = false
          // }
        }
      })
      // console.log(isExist)
      return !isExist
    },

    isItemActive(n, start, end) {
      if (this.dateArray[n] && this.dateArray[n].length) {
        let tempCol = this.dateArray[n]
        for (let i = start; i < end; i++) {
          if ((!tempCol[i].freeFlag && this.tabActiveIndex === 1) || (!tempCol[i].forbidFlag && this.tabActiveIndex === 2)) {
            return false
          }
        }
        return true
      } else {
        return false
      }
    },

    choseDateItem(item, n, type) {
      console.log('item', item, n, type)
      // const choseItem = this.formatChoseItem(item, n)
      if (this.tabActiveIndex === 1) {
        const index = this.isInArr(item, this.activeList)
        this.forbiddenActiveList
        index === -1 ? this.activeList.push(item) : type === 'chose' ? this.activeList.splice(index, 1) : ''
      } else {
        const index = this.isInArr(item, this.forbiddenActiveList)
        index === -1 ? this.forbiddenActiveList.push(item) : type === 'chose' ? this.forbiddenActiveList.splice(index, 1) : ''
      }
      // console.log(this.activeList, this.forbiddenActiveList)
    },

    formatChoseItem(item, n) {
      return {
        interviewerStartDate: item.value + n.date,
        interviewerEndDate: item.value + n.date + 30 * 60 * 1000,
      }
    },

    isInArr(item, arr) {
      const index = arr.findIndex(i => {
        return i.interviewerStartDate === item.interviewerStartDate
      })
      return index
    },
    getWeekdayList(date, weeks) {
      const weekdayList = []
      for (let i = 1; i <= 7 * weeks; i++) {
        // console.log(date, moment(date).format('MM-DD'))
        const d = date + (i - 1) * 24 * 60 * 60 * 1000
        const formatTimeArr = this.timeArr.map(t => {
          return { ...t, interviewerStartDate: d + t.value, interviewerEndDate: d + t.value + 30 * 60 * 1000, date: d }
        })
        weekdayList.push({
          dateText: moment(d).format('MM-DD'),
          weekText: moment(d).format('dddd'),
          date: d,
          timeArr: formatTimeArr.filter(i => {
            return i.value >= 28800000 && i.value <= 81000000 //8点到22点30
          }),
          morningArr: formatTimeArr.filter(i => {
            return i.value >= 28800000 && i.value < 43200000 //8点到11点30
          }),
          afternoonArr: formatTimeArr.filter(i => {
            return i.value >= 43200000 && i.value <= 81000000 //12点到22点30
          }),
          key: i,
        })
      }
      return weekdayList
    },
    cancle() {
      this.$router.go(-1)
    },
  },
  watch: {
    targetDate: {
      handler: function(targetDate) {
        // let tttt = 0
        // const timer = setInterval(() => {
        //   tttt++
        //   console.log(tttt)
        // }, 1000)
        this.changeDateArray()
        this.weekdayList = []
        const zeroPoint = targetDate
        zeroPoint.setHours(0) // 时分秒设置从零点开始
        zeroPoint.setSeconds(0)
        zeroPoint.setMinutes(0)
        zeroPoint.setMilliseconds(0)
        let date = zeroPoint.getTime()
        this.weekdayList = this.getWeekdayList(date, 1)
        this.listInterviewerBusyTime()

        console.log(' this.isSlideLeft ', this.isSlideLeft)
        // clearInterval(timer)
        setTimeout(() => {
          this.isSlideLeft = false
          this.isSlideRight = false
        }, 500)
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss">
.date-picker {
  width: 100%;
  padding-top: 5.5rem;
  text-align: center;
  .head {
    position: fixed;
    background-color: #ffffff;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0rem 0.06rem 0.11rem 0rem rgba(0, 0, 0, 0.2);
    .title-wrapper {
      text-align: left;
      width: 100%;
      padding: 0.3rem 0 0.2rem 0.6rem;
      .text {
        font-weight: 600;
        font-size: 0.4rem;
        color: #333333;
      }
      .desc {
        font-size: 0.32rem;
        color: #999999;
      }
    }

    .tab-wrapper {
      width: 100%;
      display: flex;
      font-size: 0.37rem;
      padding: 0.35rem 0;
      border-top: 0.01rem solid rgba(204, 204, 204, 0.4);
      border-bottom: 0.01rem solid rgba(204, 204, 204, 0.4);
      justify-content: space-between;
      .tab {
        flex: 1;
        text-align: center;
        font-size: 0.4rem;
      }
      .active {
        color: #1f93fd;
      }
    }
    .tips {
      width: 100%;
      padding: 0.3rem 0;
      color: #666666;
      font-size: 0.32rem;
    }

    .weekDay-wrapper {
      width: 96%;
      margin: 0 auto 0 auto;
      display: flex;
      justify-content: space-around;
      color: #666666;
      .weekDay-item-wrapper {
        width: 13.6vw;
        height: 13.6vw;
        background-color: #fafafa;
        display: flex;
        padding: 0.15rem 0;
        flex-direction: column;
        justify-content: space-around;
        font-size: 0.36rem;
      }
      .active {
        background-color: #e3f3ff;
        color: #1688f2;
      }
      .forbiddenActive {
        background-color: #fef0f0;
        color: #f56c6c;
      }
    }
  }
  .slide-right-enter-active {
    transition: all 0.2s ease;
  }
  .slide-right-leave-active {
    transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-right-enter,
  .slide-right-leave-to {
    // transform: translateX(100vw);
    opacity: 0;
  }
  .slide-left-enter-active {
    transition: all 0.2s ease;
  }
  .slide-left-leave-active {
    transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-left-enter,
  .slide-left-leave-to {
    // transform: translateX(-100vw);
    opacity: 0;
  }
  .container {
    width: 96%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    // transform: translateX(10px);

    .col {
      width: 13.6vw;
      display: flex;
      flex-direction: column;
      .pickItem {
        width: 13.6vw;
        height: 13.6vw;
        line-height: 13.6vw;
        background-color: #fafafa;
        color: #999999;
        font-size: 0.36rem;
      }
      .pickItem:not(:first-child) {
        margin-top: 0.11vw;
      }

      .active {
        background-color: #e3f3ff;
        color: #1688f2;
      }
      .forbiddenActive {
        background-color: #fef0f0;
        color: #f56c6c;
      }
    }
  }

  .foot {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 0.03rem solid #ededed;
    display: flex;
    .submit-btn {
      background-color: #1688f2;
      color: #ffffff;
      width: 100%;
      font-size: 0.4rem;
      padding: 0.35rem 0;
    }
    .cancle {
      background-color: #ffffff;
      color: #333333;
    }
  }
}
</style>
